import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import PrismicRecipeNav from '../components/PrismicRecipeNav';
import RecipeCard from '../components/RecipeCard';

const RecipeCategory = ({ data }) => {
  const { pageInfo, recipeList} = data;
  return (
    <Layout>
      <SEO title={`${pageInfo.data.title.text} Recipes`} pathname={`/recipies/${pageInfo.uid}`} />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Recipes</h1>
              <h4>{pageInfo.data.title.text}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <PrismicRecipeNav/>
        <hr/>
        <div className="row">
          <div className="col-12"
               dangerouslySetInnerHTML={{ __html: pageInfo.data.introduction.html }}/>
        </div>
        <hr/>
        <div className="row align-items-end align-text-top ">
          {recipeList.edges.map(({ node }) => (
            <div className="col-md-6 col-lg-4 mb-4">
              <RecipeCard
                title={node.data.title.text}
                category={pageInfo}
                recipeUid={node.uid}
                featureImg={node.data.featureimg}
                coverImgSize={pageInfo.data.coverimgsize}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
query ($id: String!, $uid: String! ) {
  pageInfo: prismicRecipeType(id: {eq: $id}) {
    id
    uid
    data {
      introduction {
        html
      }
      title {
        text
      }
      coverimgsize
    }
  }
  recipeList: allPrismicRecipe(filter: {data: {category: {uid: {eq: $uid}}}}) {
    edges {
      node {
        id
        uid
        data {
          title {
            text
          }
          featureimg {
            alt
            url
          }
        }
      }
    }
  }
}`;

export default RecipeCategory;
